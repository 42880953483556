
import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import GlobalMixin from '@/mixins/global'
import { parseSearch } from '@/utils/env'
import configs from '@/configs'

@Component({
  name: 'BusinessAuthorize',
  mixins: [GlobalMixin]
})
export default class BusinessAuthorizePage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public returnUrl: string = ''

  async created () {
    const queryStrings = parseSearch(location.search)
    this.returnUrl = queryStrings.returnUrl
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    // 跳转API请求链接，需要传公参
    location.href = `${configs.passportApiRoot}authorize/unify${location.search}&AgreeAuth=true`
  }
  // 放弃授权
  @Emit()
  private abandonAuthorize () {
    location.href = this.returnUrl
  }
}
